import React from 'react';
import { Template } from './Template';
import { drizzleConnect } from '@drizzle/react-plugin';

// Remove fallback when fully integrated
const View = ({ networkName = 'Unknown Network' }) => (
  <Template title="Network name">{networkName}</Template>
);

const NETWORKS = {
  '1': 'Mainnet',
  '3': 'Ropsten',
  '4': 'Kovan',
  '5': 'Goerli',
  '31': 'RSK-Testnet',
  '101': 'Localhost',
  '137': 'Polygon',
  '80002': 'Amoy',
  '42220': 'Celo',
  '44787': 'Alfajores'
};

export const Network = drizzleConnect(View, ({ web3 }) => {
  return {
    networkName: NETWORKS[web3.networkId],
  };
});
